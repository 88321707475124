import React, { useState, useEffect, useRef } from "react";
import {
  IFullAppointment,
  IMedicationSearchItem,
  IMedicationsSearch,
  IRxChangeRequest,
} from "../../book-appointment/AppointmentModels";
import {
  Col,
  Label,
  Row,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Spinner,
  Alert,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import {
  IPrescription,
  getPrescriptionsForPhysician,
  addPrescription,
  updatePrescription,
  searchMedication,
  LoadingState,
  removePrescription,
  IDoctorFavoritePrescription,
  getPhysicianFavoritePrescriptions,
  addPhysicianFavoritePrescriptions,
  removePhysicianFavoritePrescriptions,
  getAllergyInteractions,
  getDispenseUnitsItems,
  selectdoseSpotMedication,
  getDrugInteractions,
  getRxChangeRequests,
  PrescriptionStatusType,
  getPrescriptionStatusForPatient,
  IPaginatedResponse,
} from "../../../core/service/services";
import { TextField } from "../../../components/commons/TextField";
import { toastError, toastSuccess } from "../../../App";
import { AllergyInteractionResponse, IItemAllergyInteraction } from "./PhysicianAppointmentDetails";
import Asterisk from "../../../components/commons/Asterisk";
import DrugInteractionDescriptionModal from "./DrugInteractionDescriptionModal";
import { RxChangeModal } from "./RxChangeModal";
import { LoadingButton } from "../../../components/commons/LoadingButton";
import { UserConfig } from "../../../config/userConfig";
import { PharmacyInfoModal } from "./PharmacyInfoModal";
import moment from "moment";
import { ISelfReportedMedication } from "../../medical-history/SelfReportedMedicationsModal";
import { IDoseSpotPrescription } from "../../../redux/types/interfaces/medicalProfileInterfaces";
import { ApiServiceError } from "../../../core/service/ServiceCall";
export interface IDoseSotPrescription {
  DispensableDrugId: number;
  NDC: string;
  RXCUI: number;
  DisplayName: string;
  DrugName: string;
  Route: string;
  DoseForm: string;
  Strength: string;
  DispenseUnitId: number;
  Brand: true;
  GenericDrugName: string;
  OTC: true;
  DrugClassification: string;
  Schedule: number;
  StateSchedule: Array<{
    StateName: string;
    Schedule: number;
  }>;
}

interface IMedicationGrouped {
  medicationName: string;
  strengths: IMedicationSearchItem[];
}
export interface IDrugInteractionItem{
  message:string,
  description:string,
  service:string,
  drug1:string,
  drug2:string
}
interface IProps {
  appointment: IFullAppointment;
  checkForNewPrescriptions?:any;
}
export interface IPrescriptionStatusInfo {
  doseSpotId: any
  pharmacyDoseSpotId: number
  status: string
}
export const AppointmentPrescriptions: React.FC<IProps> = (props) => {
  const userType = UserConfig.getUserType(); 
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([]);
  const [modalIsOpen, setModal] = useState<boolean>(false);
  const [currentPrescription, setCurrentPrescription] = useState<IPrescription>();
  const [refills,setRefills] = useState<string>(currentPrescription?.refills?currentPrescription.refills.toString():'0')

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editOnExisting, setEditOnExisting] = useState<boolean>(false);
  const [onMed, setOnMed] = useState<boolean>(false);
  const [onStrength, setOnStrength] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<LoadingState>(
    LoadingState.nuteral
  );
  const [pageNumber,setPageNumber] = useState<number>(1)
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [loadMore,setLoadMore] = useState(false)
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);
  const [searchTerm,setSearchTerm] = useState<string>('')
  const [medicationSearchItems, setMedicationSearchItems] =
    useState<IMedicationSearchItem[]>();
  const [medicationGroupNameResult,setMedicationGroupNameResult] = useState< Map<string, IMedicationGrouped> >()
  const [medicationStrength, setMedicationStrength] = useState<string>();
  const [chosenMedication, setChosenMedication] = useState<IMedicationSearchItem>();
  const [favoriteMeds, setFavoriteMeds] = useState<
    IDoctorFavoritePrescription[]
  >([]);
  const [isFavorite, setFavorite] = useState<boolean>(false);
  const [allergyInteractions, setAllergyInteractions] = useState<IItemAllergyInteraction[]>();
  const [drugInteractions, setDrugInteractions] = useState<IDrugInteractionItem[]>();
  const [drugItem, setDrugItem] = useState<IDrugInteractionItem>();
  const [dispenseUnits, setDispenseUnits] =
    useState<{ name: any; id: any }[]>();
  const [doseSpotPrescription, setDoseSPotPrescription] =
    useState<IDoseSpotPrescription>();
  const [openDrugInteractionModal, setOpenDrugInteractionModal] =
    useState(false);
  const [rxChangeRequestsMap, setRxChangeRequestsMap] = useState<
    Record<number, IRxChangeRequest>
  >({});
  const [rxChangeModal, setRxChangeModal] = useState<boolean>(false);
  const [rxChangeRequest, setRxChangeRequest] = useState<IRxChangeRequest>();
  const [isPrescriptionBeingSubmitted, setPrescriptionBeingSubmitted] =
    useState(false);
  const [prescriptionState, setPrescriptionState] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<Date>(new Date());
  const [dispenseValue, setDispenseValue] = useState<string>();
  const [prescriptionStatusList, setPrescriptionStatusList] = useState<IPrescriptionStatusInfo[]>();

  const previousController = useRef<AbortController>();
  const favoriteMedController = useRef<AbortController>();
  
  const checkIfMorePages= (currentPage:number,totalPages:number) => {
    if(currentPage<totalPages){
      setLoadMore(true)
    }
    else{
      setLoadMore(false)

    }
  }
  const fetchMoreMedicationGroups = async () => {
    setIsFetchingMore(true);
    const newController = new AbortController()
    const response = await searchMedication(searchTerm, newController.signal, pageNumber);
    if (response && response.items.length > 0) {
      // Create a copy of the current state Map
      const additionalData = new Map(medicationGroupNameResult);

      // Process the response to add new medication groups
      response.items.forEach((medication) => {
        const nameKey = medication.nameWithRouteDoseForm;

        // If the group does not exist in the map, create a new entry
        if (!additionalData.has(nameKey)) {
          additionalData.set(nameKey, {
            medicationName: nameKey,
            strengths: [],
          });
        }

        // Add the new medication to the existing group's strengths array
        additionalData.get(nameKey)?.strengths.push(medication);
      });
      checkIfMorePages(response.pageNum,response.totalPages)
      // Update the state with the new groups
      setMedicationGroupNameResult(additionalData);

      // Increment the page number for the next fetch
      setPageNumber((prevPage) => prevPage + 1);
    }
    setIsFetchingMore(false);

  };

  const handleScroll = () => {
    const scrollDiv = scrollableDivRef.current;
    if (
      scrollDiv &&
      scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight - 1 &&
      !isFetchingMore
      && loadMore
    ) {
      fetchMoreMedicationGroups();
    }
  };

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [medicationGroupNameResult, pageNumber, isFetchingMore]);

  async function fetchPrescriptionStatusForPatient() {
    getPrescriptionStatusForPatient(props.appointment.appointmentId, props.appointment.who.patient.info.patientId)
      .then((res: any) => {
        setPrescriptionStatusList(res.items);
      })
      .catch((err) => console.log(err));
  }

  async function fetchPrescriptionsAndRxChangeRequests() {
    const presc = await getPrescriptionsForPhysician(props.appointment.appointmentId)
    setPrescriptionState(true);
    setPrescriptions(presc)
    if (presc.length > 0) {
      initializeStates();
      getRxChangeRequests(props.appointment.appointmentId).then(res => {
        let map: Record<number, IRxChangeRequest> = {};
        res.items.map(item => {
          map[item.systemPrescriptionId] = item;
        })
        setRxChangeRequestsMap(map)
      })
    }
  }
  const fetchFavoritePrescription=(signal?:AbortSignal, name?:string)=>{
    getPhysicianFavoritePrescriptions(signal, name).then(res => {
      setFavoriteMeds(res)
    }).catch(err => console.log(err));
  }
  useEffect(() => {
    fetchPrescriptionsAndRxChangeRequests()
    fetchFavoritePrescription()
    getAllergyInteractionsForPatient();
    getDrugInteractionsForPatient();
    getDispenseUnitsItems(props.appointment.appointmentId).then((result)=>{ 
      setDispenseUnits([...result]);
    }).catch((error)=>{
      console.log(error);
    })
    setDispenseValue(undefined);
    fetchPrescriptionStatusForPatient();
  }, [props.appointment]);

  const getAllergyInteractionsForPatient=()=>{
    getAllergyInteractions(props.appointment.who.patientId)
      .then((res:AllergyInteractionResponse)=>{  
        setAllergyInteractions(res.items);
      }).catch(err => console.log(err))
  }
  const getDrugInteractionsForPatient=()=>{
    getDrugInteractions(props.appointment.who.patientId)
      .then((res:IDrugInteractionItem[])=>{
        setDrugInteractions(res);
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  const toggleDrugInteractionModal = () => {
    setOpenDrugInteractionModal(!openDrugInteractionModal);
  }

  const toggleRxChangeModal = () => {
    setRxChangeModal(!rxChangeModal);
  }

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
  }

  const toggleIsFavorite = (value?: boolean) => {
    if (value === undefined) {
      setFavorite(!isFavorite)
    } else {
      setFavorite(value)
    }
  }

  const getValue = (arr: [string, FormDataEntryValue][], field: string): string => {
    for (let pair of arr) {
      if (pair[0] === field) {
        return pair[1].toString();
      }
    }
    return "";
  };

  const isDispenseAboveMaxDigit=(dispense:string)=>{
    if(dispense.length > 11){
      return true;
    }
    let arr = dispense.split('.');
    if(arr.length === 1){
      let num = arr[0];
      return (num.length > 11)
    }else{
      let num1 = arr[0];
      let num2 = arr[1];
      return ((num1.length+num2.length) > 11)
    }
  }
  const validateDispense=(e:any)=>{
    let dispense:string = e.target.value;
    let arr = dispense.split('.');
    if(arr.length>1 && Number.parseInt(arr[1]) === 0){
      setDispenseValue(arr[0]);
    }
    else if(arr.length>1 && arr[0]===""){
      setDispenseValue(`0.${arr[1]}`);
    }
    else{
      setDispenseValue(dispense);
    }
  }
  const fetchPrescriptionAfterMutation=()=>{
    getPrescriptionsForPhysician(props.appointment.appointmentId)
        .then((presc)=>{
          setPrescriptions(presc);
          fetchPrescriptionStatusForPatient();
        })
        .catch((error)=>{console.log(error)});
  }
  const save =async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let errorCount = 0;
    const data: FormData = new FormData(e.currentTarget);
    const formData: [string, FormDataEntryValue][] = Array.from(data.entries());
    const medicationName =
      chosenMedication?.nameWithRouteDoseForm || currentPrescription?.medicationName;
    const dispense = getValue(formData, "dispense");
    const daysSupply = getValue(formData, "daysSupply");
    const dispenseUnit = getValue(formData, "dispenseUnit");
    const directionsForUse = getValue(formData, "directionsForUse");
    const pharmacyNotes = getValue(formData, "pharmacyNotes");
    if (
      medicationName === "" ||
      refills === "" ||
      dispense === "" ||
      directionsForUse === "" ||
      dispenseUnit === ""
    ) {
      toastError("Please fill mandatory fields");
      return;
    }
    if(isDispenseAboveMaxDigit(dispense)){
      toastError("Dispense digits must be at most 11!");
      return;
    }
    setPrescriptionBeingSubmitted(true)
    if (editMode && currentPrescription) {
      currentPrescription.medicationName = medicationName || "";
      currentPrescription.dispense = dispense;
      currentPrescription.refills = Number.parseInt(refills);
      currentPrescription.daysSupply = Number.parseInt(daysSupply);
      currentPrescription.effectiveDate =
        moment(effectiveDate).format("YYYY-MM-DD");
      currentPrescription.directionsForUse = directionsForUse;
      currentPrescription.pharmacyNotes = pharmacyNotes;
      currentPrescription.dispenseId = Number.parseInt(dispenseUnit)
      let res:IPrescription|any = await updatePrescription(props.appointment.appointmentId,currentPrescription).catch((error: ApiServiceError)=>{
        errorCount = errorCount + 1
        toastError(error.json?.message ?? "An error occurred while updating the prescription");
      })
      if(res) {
        const items = prescriptions;
        items.forEach((item) => {
          if (item.id === res.id) {
            item.medicationId = res.medicationId;
            item.medicationName = res.medicationName;
            item.dispense = res.dispense;
            item.refills = res.refills;
            item.daysSupply = res.daysSupply;
            item.directionsForUse = res.directionsForUse;
            item.pharmacyNotes = res.pharmacyNotes;
            item.dispenseId = res.dispenseId;
            item.status = res.status;
            item.pharmacy = res.pharmacy
            item.effectiveDate = res.effectiveDate
          }
        });
        setPrescriptions(items);
        setDispenseValue(undefined);
        getAllergyInteractionsForPatient();
        getDrugInteractionsForPatient();
        props.checkForNewPrescriptions();
      }
      if(errorCount === 0){ 
      toastSuccess("Prescription Updated!");
      toggleEditMode(false);
    }
    } else {
      
      const despensableDrugId = chosenMedication ? chosenMedication.dispensableDrugId : parseInt(currentPrescription!.medicationId)
      
      const item: IPrescription = {
        medicationId: despensableDrugId.toString(), //should add medication NCS returned from dosespot
        medicationName: medicationName || "",
        dispense: dispense,
        refills: Number.parseInt(refills),
        daysSupply: Number.parseInt(daysSupply),
        effectiveDate: moment(effectiveDate).format("YYYY-MM-DD"),
        directionsForUse: directionsForUse,
        pharmacyNotes: pharmacyNotes,
        strength: chosenMedication?.strength || currentPrescription?.strength || "-",
        ordered: false,
        dispenseId: Number.parseInt(dispenseUnit),
        dispensableDrugId: despensableDrugId,
        rxcui: chosenMedication?.rxcui
      };
      let response:IPrescription|any = await addPrescription(props.appointment.appointmentId, {...item, ...doseSpotPrescription})
      .catch((error)=>{
        errorCount = errorCount + 1
        let obj:any = Object.values(error)[2];
        toastError(obj.message);
        
      });
      if(response) {
        setPrescriptions([...prescriptions, response]);
        fetchPrescriptionStatusForPatient();
        
        getAllergyInteractionsForPatient();
        getDrugInteractionsForPatient();
        setDispenseValue(undefined);
        props.checkForNewPrescriptions();
        
      }
      if (isFavorite) {
        const favPres: IDoctorFavoritePrescription = {
          medicationId: item.dispensableDrugId.toString(),
          medicationName: item.medicationName,
          dispense:item.dispense,
          refills: item.refills,
          directionsForUse: item.directionsForUse,
          pharmacyNotes: item.pharmacyNotes,
          strength:item.strength,
          dispenseUnitId: item.dispenseId
        }
        let addedFavourite = await addPhysicianFavoritePrescriptions(favPres).catch((err) => {
          console.log(err);
          errorCount = errorCount +1;
        })
        if(addedFavourite) {
          const newFavs = favoriteMeds.concat(addedFavourite)
          setFavoriteMeds(newFavs)
        }
      }
      if(errorCount === 0) toastSuccess("Prescription Added!");
    }
    setPrescriptionBeingSubmitted(false)
    if(errorCount === 0) toggleModal();
  };
  
  const toggleModal = () => {
    initializeStates();
    setModal(!modalIsOpen);
    setFavorite(false)
    fetchFavoritePrescription()
  };

  const toggleEditMode = (mode: boolean) => {
    setEditMode(mode);
  };

  const onAddClicked = () => {
    toggleEditMode(false);
    setRefills('0')
    setCurrentPrescription(undefined)
    toggleModal();
  };

  const onFavoriteMedicationClicked = (item: IDoctorFavoritePrescription) => {
    if (!item.medicationId) {
      return;
    }
    setCurrentPrescription({
      medicationId:item.medicationId,
      medicationName: item.medicationName,
      dispense: item.dispense,
      refills: item.refills,
      directionsForUse: item.directionsForUse,
      dispensableDrugId: parseInt(item.medicationId),
      pharmacyNotes: item.pharmacyNotes || "",
      ordered: false,
      strength: item.strength,
      dispenseId: item.dispenseUnitId,
      daysSupply: 0,
      effectiveDate: new Date()
    })
    setOnStrength(true);
  }

  const onEditClicked = (item: IPrescription) => {
    toggleEditMode(true);
    setCurrentPrescription(item);
    setRefills(item.refills.toString())
    toggleModal();
  };

  const initializeStates = () => {
    setOnStrength(false);
    setOnMed(false);
    setMedicationStrength(undefined);
    setChosenMedication(undefined);
    setMedicationSearchItems(undefined);
    setMedicationGroupNameResult(undefined)
    setIsLoading(LoadingState.nuteral);
    setDoseSPotPrescription(undefined);
    setDispenseValue(undefined);
    
  };

  const searchForFavoriteMedByName=(name:string)=>{
    if (favoriteMedController.current) {
      favoriteMedController.current.abort();
    }
    const newController = new AbortController()
    const signal = newController.signal
    favoriteMedController.current = newController;
    fetchFavoritePrescription(signal, name);
  }
  const handleChange = async (value: any) => {
    initializeStates();
    searchForFavoriteMedByName(value)
    if (value.length >= 3) {
      setIsLoading(LoadingState.loading);
      if (previousController.current) {
        previousController.current.abort();
      }
      const controller = new AbortController()
      const signal = controller.signal
      previousController.current = controller;
      let response = await searchMedication(value, signal);
      let result = response;
      if (response && result.items.length > 0) {
        setIsLoading(LoadingState.succeed);
      } else {
        setIsLoading(LoadingState.failed);
      }

      const searchResult = new  Map<string, IMedicationGrouped>();
      
      result.items.forEach((medication) => {
        const nameKey = medication.nameWithRouteDoseForm;
    
        if (!searchResult.has(nameKey)) {
          searchResult.set(nameKey, {
            medicationName: nameKey,
            strengths: [],
          });
        }
    
      });
      setSearchTerm(value)
      setMedicationGroupNameResult(searchResult)
      checkIfMorePages(result.pageNum,result.totalPages)
      setPageNumber(2) //next pageNumber to be fetched will be 2

            // setMedicationSearchItems(result.items);
    }
  };

  const onMedClick = (item:IMedicationSearchItem) => {
    if (item.strength === undefined) {
      setOnStrength(true);
      setChosenMedication({ ...item, strength: "-" });
    } else {
      setChosenMedication({ ...item});
      setMedicationStrength(item.strength);
    }
  };

  const onRemoveClicked = (e: React.MouseEvent, prescription: IPrescription) => {
    e.preventDefault()
    setPrescriptionState(false);
    removePrescription(props.appointment.appointmentId, prescription.id || 0).then(res => {
      let copyPres = [...prescriptions]
      const idx  = copyPres.indexOf(prescription)
      setPrescriptionState(true);
      if (idx > -1) {
        copyPres.splice(idx, 1)
        setPrescriptions(copyPres)
        getAllergyInteractionsForPatient();
        getDrugInteractionsForPatient();
        props.checkForNewPrescriptions();
        toastSuccess("Prescription removed successfully!")
      }
    }).catch(err => {toastError(err); setPrescriptionState(true);})
    
  }

  const removeFavoriteMed = (item: IDoctorFavoritePrescription) => {
    item.id && removePhysicianFavoritePrescriptions(item.id).then(res => {
      let copyPres = [...favoriteMeds]
      const idx  = copyPres.indexOf(item)
      if (idx > -1) {
        copyPres.splice(idx, 1)
        setFavoriteMeds(copyPres)
        toastSuccess("Medication removed from favorite prescriptions list!")
      }
    })
  }

  const renderLoadingState = () => {
    if (isLoading === LoadingState.loading) {
      return <Spinner color="primary" />;
    } else if (isLoading === LoadingState.failed) {
      return (
        <Alert color="danger">
          No matching found!
        </Alert>
      )
    }
    return null;
  };

  const onMedicationSelected = (item: IMedicationSearchItem) => {
    setChosenMedication(item)
  }

  
  function groupMedicationsByName(
    medications: IMedicationSearchItem[]
  ): IMedicationGrouped[] {
    const groupedMedicationsMap = new Map<string, IMedicationGrouped>();
  
    medications.forEach((medication) => {
      const nameKey = medication.nameWithRouteDoseForm;
  
      if (!groupedMedicationsMap.has(nameKey)) {
        groupedMedicationsMap.set(nameKey, {
          medicationName: nameKey,
          strengths: [],
        });
      }
    });
  
    return Array.from(groupedMedicationsMap.values());
  }

  const searchForMedicationStrengths = async (name:string,index:number) => {
    const newController = new AbortController()
    const signal = newController.signal
    let searchMedicationsResult = await searchMedication(name, signal);
    if(searchMedicationsResult.items.length===1){ 
      const medication =searchMedicationsResult.items[0]
      onMedClick(medication);
      onStrengthChosen(medication)
    }
    else{
      if (medicationGroupNameResult?.get(name)?.strengths?.length === 0) {

        searchMedicationsResult.items.forEach((medication) => {
          const nameKey = medication.nameWithRouteDoseForm;
          medicationGroupNameResult?.get(nameKey)?.strengths.push(medication);
        });
      }

      expandedIndex===index?setExpandedIndex(undefined):setExpandedIndex(index); 
    }

  }

  const handleGroupMedicationClick = (group:IMedicationGrouped,index:number) => {

    searchForMedicationStrengths(group.medicationName,index)
    
  }
  const renderGroupedMedicationSearchItems = () => {
    if (medicationGroupNameResult) {
      return (
      <div
        ref={scrollableDivRef}
        style={{ maxHeight: "300px", overflowY: "auto", border: "1px solid #ddd", padding: "10px" }}
      >
        <Row>
          {/* Use `map` instead of `forEach` to render the components */}
          {Array.from(medicationGroupNameResult.entries()).map(([key, group], index) => (
            <Col md="6" lg="6" key={key}>
              <Button
                style={{ textAlign: "left" }}
                id={index.toString()}
                onClick={() => handleGroupMedicationClick(group, index)}
                color="link"
              >
                {group.medicationName}
              </Button>
  
              {/* Render Strength Options */}
              {expandedIndex === index && (
                <div style={{ marginLeft: "15px" }}>
                  {group.strengths.map((strengthItem, subIndex) => (
                    <Button
                      key={subIndex}
                      id={`${index}-${subIndex}`}
                      onClick={() => {
                        onMedClick(strengthItem);
                        onStrengthChosen(strengthItem);
                      }}
                      color="link"
                    >
                      {strengthItem.strength}
                    </Button>
                  ))}
                </div>
              )}
            </Col>
          ))}
        </Row>
        </div>
      );
    }
    return null;
  };
  
  const onStrengthChosen = (chosenMedication: IMedicationSearchItem) => {
    if (chosenMedication) {
      setOnStrength(true);
      selectdoseSpotMedication(chosenMedication.dispensableDrugId, chosenMedication.nameWithRouteDoseForm).then(
        (result) => {
          setMedicationStrength(chosenMedication.strength);
          return setDoseSPotPrescription({...result,Strength:chosenMedication.strength});
        }
      )
    }
  }

  const renderChosenMedAndStrength = () => {
    return (
      <Row className="mt-2 mb-4 pl-1">
        <Col md="12">
          <div>
            Medication:{" "}
            {chosenMedication?.nameWithRouteDoseForm || currentPrescription?.medicationName}
            {!editMode && (
              <Button
                color="link"
                size="sm"
                className="pt-0"
                onClick={() => {
                  initializeStates();
                  fetchFavoritePrescription();
                  setEditOnExisting(true);
                }}
              >
                <span className="sr-only">Edit</span>{" "}
                <i className="fa fa-edit fa-lg"></i>
              </Button>
            )}
          </div>
        </Col>
        <Col md="12">
          Strength:{" "}
          {medicationStrength || currentPrescription?.strength}
          {medicationStrength && !editMode && (
            <Button
              color="link"
              size="sm"
              className="pt-0"
              onClick={() => setOnStrength(false)}
            >
              <span className="sr-only">Edit</span>{" "}
              <i className="fa fa-edit fa-lg"></i>
            </Button>
          )}
        </Col>
      </Row>
    );
  };
  const renderDispenseInput=()=>{
    return(
      <FormGroup className="form-label-group">
          <Label>Dispense<Asterisk /></Label>
          <Input
            maxLength={11} step={0.00001} precision={5} type="number" placeholder="" name="dispense" required
            defaultValue={ currentPrescription?.dispense + ""} onBlur={validateDispense} onChange={(e:any)=>{
              setDispenseValue(e.target.value)}} value={dispenseValue}
            >
          </Input>
        </FormGroup>
    )
  }
  const renderPrescriptionForm = () => {
    return (
      <>
        <Col md="6">
          {renderDispenseInput()}
        </Col>
        <Col md="6">
          <TextField textChanged={(e)=>setRefills(e)} type="number" placeholder="" labelText="Refills" name="refills"  required 
            value={refills}/>
        </Col>
        <Col md="6">
          <TextField type="number" placeholder="" labelText="Days Supply" name="daysSupply" 
            defaultValue={currentPrescription?.daysSupply + ""}/>
        </Col>
        <Col md="6">
          <div className="custom-control custom-checkbox mt-4">
            <Input type="checkbox" className="custom-control-input" name="allow-subs" checked={true}/>
            <Label className="custom-control-label" htmlFor="allow-subs">Allow Substitutions</Label>
          </div>  
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="effectiveDate">Effective Date:</Label>
            <Input type="date" id="effectiveDate" name="effectiveDate" min={moment(new Date()).format("YYYY-MM-DD")} required
              defaultValue={currentPrescription? moment.utc(currentPrescription?.effectiveDate).format("YYYY-MM-DD") : moment.utc(effectiveDate).format("YYYY-MM-DD")} 
              onChange={(e) => {
                const selectedDate = moment(e.target.value).local().toDate();
                setEffectiveDate(selectedDate || new Date());
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
              <Label>Dispense Unit <Asterisk/></Label>
              <Input type="select" required name="dispenseUnit" >
              {dispenseUnits?.map((item)=>{
              return <option selected = {item.id === (doseSpotPrescription?.DispenseUnitId || currentPrescription?.dispenseId)} value={item.id}>{item.name}</option>
            })}
              </Input>
              </FormGroup>
        </Col>
        <Col md="12">
          <TextField
            type="textarea"
            placeholder=""
            labelText="Directions for Use"
            name="directionsForUse"
            required
            defaultValue={currentPrescription?.directionsForUse}
          />
        </Col>
        <Col md="12">
          <TextField type="textarea" placeholder="" labelText="Pharmacy Notes" name="pharmacyNotes" 
            defaultValue={currentPrescription?.pharmacyNotes}/>
        </Col>
      </>
    );
  };

  const renderFavoriteMed = () => {
    return (
      <Col md="4">
        <div onClick={() => toggleIsFavorite()}>
          <i style={{color: isFavorite? "orange" : "black"}}  className={isFavorite? "fas fa-star": "far fa-star"}></i>
          <text> Set as Favorite</text>
        </div>
      </Col>
    )
  }

  const renderAllergyInteractions=()=>{
    return (
      <Alert color="danger">
        <h6>Allergy Interactions: </h6>
        {allergyInteractions?.map((item)=>{
          return (
            <>
            {item.interactionMessage}
            <br />
            </>
            )
        })}
      </Alert>
    )
  }

  const renderDrugInteractionDescriptionModal=()=>{
    return(
      drugItem && 
        <DrugInteractionDescriptionModal service={drugItem.service} drug1={drugItem.drug1} drug2={drugItem.drug2}
            description={drugItem.description} isOpen={openDrugInteractionModal}  toggle={toggleDrugInteractionModal} />
    )
  }
  const renderDrugInteraction=()=>{
    return(
      <Alert color="danger">
        <h6>Drug Interactions</h6>
        { drugInteractions?.map((item)=>{
          return(
            <>
            <a href="#" className="alert-link" onClick={(e)=>{e.preventDefault();setDrugItem(item); toggleDrugInteractionModal()}}>{item.message}</a>
            <br />
            </>
          )
        })

        }
        
      </Alert>
    )
  }
   
  const getPrescription=(doseSpotId:string)=>{
    return prescriptionStatusList?.filter((item)=>{return (item.doseSpotId == doseSpotId)}).pop()
  }
  const previewStatusToIcon=(doseSpotId:string)=>{
    let status = getPrescription(doseSpotId)?.status;

    switch(status){
      case(PrescriptionStatusType.Sending):
        return(
          <>
          <UncontrolledTooltip placement="top" target="share">Sending</UncontrolledTooltip>
          <i className="fa fa-share" style={{color:"blue"}} id="share"></i>
          </>
        )
      case(PrescriptionStatusType.eRxSent):
        return(
          <>
          <UncontrolledTooltip placement="top" target="eRxSent">Sent</UncontrolledTooltip>
          <i className="fa fa-check" style={{color:"green"}} id="eRxSent"></i>
          </>
        )
      case(PrescriptionStatusType.Printed):
        return(
          <>
          <UncontrolledTooltip placement="top" target="printed">Printed</UncontrolledTooltip>
          <i className="fa fa-print" style={{color:"green"}} id="printed"></i>
          </>
        )
      case(PrescriptionStatusType.Entered):
        return(
          <>
          <UncontrolledTooltip placement="top" target="entered">Entered</UncontrolledTooltip>
          <i className="fa fa-external-link-square-alt" style={{color:"blue"}} id="entered"></i>
          </>
        )
      case(PrescriptionStatusType.Edited):
        return(
          <>
          <UncontrolledTooltip placement="top" target="edited">Edited</UncontrolledTooltip>
          <i className="fa fa-edit" style={{color:"blue"}} id="edited"></i>
          </>
        )
      case(PrescriptionStatusType.Error):
        return(
          <>
          <UncontrolledTooltip placement="top" target="error">Error</UncontrolledTooltip>
          <i className="fa fa-times" style={{color:"red"}} id="error"></i>
          </>
        )
      case(PrescriptionStatusType.PharmacyVerified):
        return(
          <>
          <UncontrolledTooltip placement="top" target="pharmacyVerified">Pharmacy Verified</UncontrolledTooltip>
          <i className="fas fa-prescription-bottle-alt" style={{color:"green"}} id="pharmacyVerified"></i>
          </>
        )
      case(PrescriptionStatusType.Deleted):
        return(
          <>
          <UncontrolledTooltip placement="top" target="deleted">Deleted</UncontrolledTooltip>
          <i className="fa fa-trash-alt" style={{color:"black"}} id="deleted"></i>
          </>
        )
      case(PrescriptionStatusType.Requested):
        return(
          <>
          <UncontrolledTooltip placement="top" target="requested">Requested</UncontrolledTooltip>
          <i className="fa fa-check-circle" style={{color:"green"}} id="requested"></i>
          </>
        )
      break;
    }
  }
  const isActiveAppointment = () => {
    return (props.appointment.status === "Completed" ||
      props.appointment.status === "Started")
  }

  const renderViewRxChangeBtn = (id: number) => (
    <Button color="danger" onClick={() => {setRxChangeRequest(rxChangeRequestsMap[id]); toggleRxChangeModal();}}>View</Button>
  )
  const renderLoadingPrescription = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          type="grow"
          color="primary"
        />
        Loading...
      </div>
      )
  }

  return (
    <>
      <Modal size="lg" isOpen={modalIsOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
            Treatment Plan {">"} Medications
            {editMode ? " > " + currentPrescription?.medicationName : ""}
        </ModalHeader>
        <ModalBody>
          <h6>
            {editMode? "Update Prescription Info" : "Enter all treatment medications, as part of treatment plan"}
          </h6>
          <fieldset disabled={isPrescriptionBeingSubmitted}>
          <Form onSubmit={save}>
            <Row>
              {!onStrength && (
                <>
                  {!editMode || editOnExisting ? (
                    <>
                      <Col md="12">
                        {favoriteMeds && favoriteMeds.length > 0 && (
                          <Label>
                            Select from list of saved prescriptions or search
                            for a new drug
                          </Label>
                        )}
                        <ListGroup className="mb-2" name="favoriteMedications" style={{ maxHeight: "10rem", overflowY: "auto" }}>
                          {favoriteMeds.map((item) => (
                            <ListGroupItem>
                              <Row>
                                <Col md="10">
                                  <ListGroupItemText  style={{
                                    color: !item.medicationId  ? 'red' : 'blue',
                                    pointerEvents: !item.medicationId  ? 'none' : 'auto', // Disable interaction if conditions are met
                                    textDecoration:!item.medicationId ?'none':'auto', // To prevent underline for disabled links
                                    }} 
                                    tag="a" href="#"         
                                    onClick={(e) => {e.preventDefault(); onFavoriteMedicationClicked(item);}}>
                                    {item.medicationName + " (" + item.strength + ") - Disp.: " + item.dispense}
                                  </ListGroupItemText>
                                </Col>
                                <Col md="2">
                                  <Button color="link" size="sm" className="pt-0 float-right" onClick={() => removeFavoriteMed(item)}>
                                    <span className="sr-only">Remove</span>{" "}
                                    <i className="fa fa-trash fa-lg"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col md="8">
                        <TextField type="text" placeholder="Search for a medication" labelText="Medication Name" name="medicationName" required
                          defaultValue={editMode ? currentPrescription?.medicationName : ""} textChanged={handleChange}/>
                      </Col>
                    </>
                  ) : (
                    <Col md="8">{renderChosenMedAndStrength()}</Col>
                  )}
                  <Col md="4">{renderLoadingState()}</Col>
                  <Col md="12">{renderGroupedMedicationSearchItems()}</Col>
                </>
              )}

              {onStrength && (
                <>
                  <Col md="8">{renderChosenMedAndStrength()}</Col>
                  {renderFavoriteMed()}
                </>
              )}
              {onStrength || editMode ? renderPrescriptionForm() : null}
            </Row>
            {(onStrength || editMode) && (
              <LoadingButton color="primary" size="sm" block={true} className="w-50 m-auto" type="submit" isLoading={isPrescriptionBeingSubmitted}>
                {editMode ? "Update " : "Add "} Prescription
              </LoadingButton>
            )}
          </Form>
          </fieldset>
        </ModalBody>
      </Modal>
      {rxChangeRequest &&
        <RxChangeModal modalIsOpen={rxChangeModal} toggleModal={toggleRxChangeModal} onRxChangeAction={fetchPrescriptionsAndRxChangeRequests}
          rxChangeRequest={rxChangeRequest} appointmentId={props.appointment.appointmentId}/>
      }
      <Col sm="12" className="mb-3"> 
          {isActiveAppointment() && prescriptions.length > 0 &&
            (<>
            {drugInteractions && drugInteractions.length>0 ? renderDrugInteraction():null}
            {renderDrugInteractionDescriptionModal()}
            {allergyInteractions && allergyInteractions.length>0?renderAllergyInteractions():null}
            </>)
          }
        <dl>
          <dt>
            <Label>Prescriptions</Label>
          </dt>
          <dd>{
          (!prescriptionState) ?
            renderLoadingPrescription()
            :
            prescriptions.length > 0 ? (
              <Table size="sm" className="ml-2" responsive striped>
                <thead>
                  <th>Name</th>
                  <th>Strength</th>
                  <th>RxChange</th>
                  <th>Amount</th>
                  <th>Refills</th>
                  <th>Status</th>
                  <th>Pharmacy</th>
                  {(userType !== "physician") && <th>Notes</th>}
                </thead>
                <tbody>
                  {prescriptions.map((item) => (
                    <tr style={{color: (item.id && rxChangeRequestsMap[item.id])? "red" : ""}}>
                      <td>{item.medicationName}</td>
                      <td>{item.strength}</td>
                      <td>{(item.id && rxChangeRequestsMap[item.id])? renderViewRxChangeBtn(item.id) : "-"}</td>
                      <td>{item.dispense}</td>
                      <td>{item.refills}</td>
                      <td>{(item.doseSpotId) && previewStatusToIcon(item.doseSpotId)}
                      </td>

                      {item.doseSpotId && (
                        <PharmacyInfoModal
                          appointmentId={props.appointment.appointmentId}
                          currentPrescritpionInfo={prescriptionStatusList
                            ?.filter((pres) => {
                              return pres.doseSpotId == item.doseSpotId;
                            })
                            .pop()}
                        />
                      )}

                      {userType !== "physician" && (
                        <td>{item.directionsForUse}</td>
                      )}
                      {!item.ordered && isActiveAppointment() && (
                        <>
                          <td>
                            <Button color="link" size="sm" className="pt-0" onClick={() => onEditClicked(item)}>
                              <span className="sr-only">Edit</span>{" "}
                              <i className="fa fa-edit fa-lg"></i>
                            </Button>
                          </td>
                          <td>
                            <Button color="link" size="sm" className="pt-0" onClick={(e) => onRemoveClicked(e, item)}>
                              <span className="sr-only">Remove</span>{" "}
                              <i className="fa fa-trash fa-lg"></i>
                            </Button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h6>None</h6>
            )}
            {(isActiveAppointment() && (prescriptionState)) && (
              <Button size="sm" outline block color="primary" className="w-50 m-auto" onClick={onAddClicked}>
                Add Prescription
              </Button>
            )}
            
          </dd>
        </dl>
      </Col>
    </>
  );
};
