import React, { useState } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../../config/Routes";
import { Login } from '../Login';
import { useAgentAuthService } from "../../../core/service/services";
import { UserConfig } from '../../../config/userConfig';

interface IAgentLogInProps extends IProps {
    email?: string;
  }
interface IProps extends RouteComponentProps {
  targetPath?: string
}
export const AgentLogin:React.FC<IProps> = (props: IAgentLogInProps) =>{
    const [errorMessage, setErrorMessage] = useState('');
    const signInService = useAgentAuthService();


    React.useEffect(() => {
      if (signInService.service.status === "loaded") {
        UserConfig.setToken(signInService.service.payload.accessToken);
        UserConfig.setUserInfo(signInService.service.payload.userInfo);
        UserConfig.setUserType("agent")
        
        props.targetPath? navigate(props.targetPath) : navigate(Routes.homeAgent);
        
      } else if (signInService.service.status === "error") {
        setErrorMessage("Password is wrong or does not exist");
      } else if (signInService.service.status === "validationError") {
        setErrorMessage('Password is missing or Email is not valid');
      } else if (signInService.service.status === "custormError" ){
        setErrorMessage(signInService.service.errorResponse.details);
      }
  
    });
    
    const logIn = (e:{email?:string, password?:string})=>{
      let data = { email: '', password: '' };
      data.email = e.email || '';
      data.password = e.password || '';

      signInService.callService(data)
    }
    return (
        <Login userType="agent" logIn={logIn} routesTo={Routes.agentSignup} errorMessage={errorMessage}/>
      );
}