// import { render } from "@testing-library/react";
import React, { useState, FormEvent } from 'react';
import { TextField } from "../../components/commons/TextField";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { useSignInAPI } from "../../core/service/services";
import { UserConfig } from "../../config/userConfig";
import { EmptyButton } from '../../components/commons/EmptyButton';
import { Button, Form, CardBody, CardTitle } from 'reactstrap';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {
  targetPath?: string
} //, WithTranslation {}

export const PatientSignIn: React.FC<IProps> = (props) => {

  const [errorMessage, setErrorMessage] = useState('');

  let email = (props.location?.state as any)?.email;
  let password = (props.location?.state as any)?.password;

  const emailField = new TextField({
    type: "text",
    labelText: "Email address",
    placeholder: "Email address",
    textChanged: () => { },
    defaultValue: email
  });
  const passwordFiled = new TextField({
    type: "password",
    labelText: "Password",
    placeholder: "Password",
    textChanged: () => { },
    defaultValue: password
  });

  const signInService = useSignInAPI();

  const data = {
    email: "",
    password: "",
  };

  React.useEffect(() => {
    if (signInService.service.status === "loaded") {
      UserConfig.setToken(signInService.service.payload.accessToken);
      UserConfig.setUserInfo(signInService.service.payload.userInfo);
      UserConfig.setUserType("patient")
      if (signInService.service.payload.userInfo &&
        signInService.service.payload.userInfo.visitCount < 1) {
        navigate(Routes.quick);
      } else {
        props.targetPath ? navigate(props.targetPath) : navigate(Routes.homePatient);
      }
    } else if (signInService.service.status === "error") {
      setErrorMessage("Password is wrong or does not exist");
    } else if (signInService.service.status === "validationError") {
      setErrorMessage('Password is missing or Email is not valid');
    } else if (signInService.service.status === "custormError") {
      setErrorMessage(signInService.service.errorResponse.details);
      if (signInService.service.errorResponse.responseCode === 423) {
        localStorage.setItem('email', emailField.getValue() || '')
        navigate(Routes.resendEmail, { state: { signUp: false } })
      } 
    }

  });

  const callLoginService = (e: FormEvent) => {
    e.preventDefault()
    data.email = emailField.getValue() || "";
    data.password = passwordFiled.getValue() || "";

    signInService
      .callService(data)
      .then((resData) => {
      })
      .catch((error) => {
        console.log(error);
        // alert( "error is " + JSON.stringify( error  ));
      });
  };

  return (
    <>
      <Helmet>
        <title>Patient Sign-in - TelMDCare</title>
        <meta name="description" content="Find all your confidential online doctor consultation records at TelMDCare by signing in with your email address and password." />
      </Helmet>
      <SmallCardFrame navItem={null} bodyClassName="tc-bg">
        <div className="card-img-left d-none d-md-flex">
          {/* <!-- Background image for card set in CSS! --> */}
        </div>
        <CardBody>
          <CardTitle className="text-center">Patient Login</CardTitle>
          <hr />
          <Form className="form-signin" onSubmit={(e) => callLoginService(e)}>
            {emailField.render()}
            {passwordFiled.render()}
            <div className="text-center mb-4 mt-4" style={{ color: "red" }}>
            {errorMessage}
          </div>

            <Button type="submit" size="lg" color="primary" className="btn-block text-uppercase btn-login mt-4">Sign In</Button>

            <div className="text-center mb-4 mt-4">
              Forgot Password? {" "}
              <a onClick={(e) => { navigate(Routes.requestReset, { state: { email: emailField.getValue(), userType: "patient" } }); e.preventDefault(); return false; }} href="#" className=" mt-2"> Reset Here</a>
            </div>
            <hr />
            <div className="form-text mt-2 text-center mb-2 mt-4">
              Not yet a member?
            </div>
            <EmptyButton label="Sign Up" to={Routes.patientSignup} />
          </Form>
        </CardBody>
      </SmallCardFrame>
    </>
  );
};
