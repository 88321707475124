import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, Row, Col, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { toastError } from "../../../App";
import Asterisk from "../../../components/commons/Asterisk";
import { LoadingButton } from "../../../components/commons/LoadingButton";
import { TextField } from "../../../components/commons/TextField";
import { appointmentBillingUpdate, getBillingManageCapture, refundAmountAppointment } from "../../../core/service/services";
import { BillingStatus, IAppointmentBiller, IInitialFees } from "./HomeBiller";
import { toastSuccess } from './../../../App';
import { isNil } from "lodash";

interface IManageInsuranceModal {
    appointement?: IAppointmentBiller;
    isOpen: boolean
    toggleModal: any
    initialFees?: IInitialFees
    updateBillingStatus: any
}

export interface DataForAmount {
    amount: number

}
export interface IAppointementBilling {
    refundedAmount: number,
    allowedAmount: number,
    refundStatus: RefundStatus
    paidByInsurance?: number,
    notes?: string,
    amountPaidByInsurance?: number,
    processed?: number,
    submitted?: number
}

export interface IAppointementBillingUpdate {
    paidByInsurance?: boolean,
    notes?: string,
    amountPaidByInsurance?: number,
    processed?: boolean,
    submitted?: boolean
}

export interface IRefundAmountApiResponse {
    appointmentBilling: IAppointementBilling
    comment: string
}

export enum RefundStatus {
    Succeeded = "Succeeded",
    Failed = "Failed"
}

let initialValuePaidByInsurance: boolean | undefined = undefined
let initialValuePaidByInsuranceNumeric: number | undefined = undefined
let initialValueNotes: string | undefined = undefined
export const ManageInsuranceModal = (props: IManageInsuranceModal) => {
    const MAX_CHAR = 1024;

    const [refundStatus, setRefundStatus] = useState<RefundStatus | undefined>(undefined);
    const [isRefundLoading, setIsRefundLoading] = useState<boolean>(false)
    const [amountToRefund, setAmountToRefund] = useState<number | undefined>(undefined)
    const [billingValues, setBillingValues] = useState<IAppointementBilling | undefined>()
    const [paidByInsurance, setPaidByInsurance] = useState(false);
    const [paidByInsuranceNumeric, setPaidByInsuranceNumeric] = useState<number | undefined>(undefined);
    const [notes, setNotes] = useState<string | undefined>(undefined);
    const [savedNotes, setSavedNotes] = useState<string | undefined>(undefined);
    const refundField: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (props.isOpen) {
            if (props.appointement?.appointmentNumber) {
                getBillingManageCapture(props.appointement?.appointmentNumber)
                    .then((result: IAppointementBilling) => {
                        if (Object.keys(result).length === 0) {
                            setRefundStatus(undefined);
                        } else {
                            if (result.refundStatus) {
                                setRefundStatus(result.refundStatus);
                                if (areThereOtherBillingValues(result)) {
                                    fillOtherBillingValuesStates(result);
                                }
                            } else {
                                setRefundStatus(undefined);
                                fillOtherBillingValuesStates(result);
                            }
                            setBillingValues(result);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }, [props.appointement, props.isOpen])

    const areThereOtherBillingValues = (result: IAppointementBilling) => {
        if (result.paidByInsurance != undefined || result.amountPaidByInsurance != undefined || result.notes) {
            return true;
        }
        return false;
    }

    const fillOtherBillingValuesStates = (result: IAppointementBilling) => {
        if (result.paidByInsurance === undefined) {
            initialValuePaidByInsurance = undefined;
        } else {
            setPaidByInsurance(result.paidByInsurance === 1)
            initialValuePaidByInsurance = result.paidByInsurance === 1;
        }
        if (result.amountPaidByInsurance === undefined) {
            initialValuePaidByInsuranceNumeric = undefined;
        } else {
            setPaidByInsuranceNumeric(result.amountPaidByInsurance)
            initialValuePaidByInsuranceNumeric = result.amountPaidByInsurance;
        }
        if (result.notes === undefined) {
            initialValueNotes = undefined
        } else {
            setNotes(result.notes)
            initialValueNotes = result.notes
        }
    }

    useEffect(() => {
        if (props.isOpen) {

        } else {
            setRefundStatus(undefined)
            setAmountToRefund(undefined)
            setBillingValues(undefined)
            setPaidByInsurance(false)
            setPaidByInsuranceNumeric(0)
            setNotes(undefined)
            setSavedNotes(undefined)

            initialValuePaidByInsurance = undefined
            initialValuePaidByInsuranceNumeric = undefined
            initialValueNotes = undefined
        }
    }, [props.isOpen])

    const refundAmountApi = () => {
        if (amountToRefund === undefined) {
            return toastError("Please enter an amount to refund");
        }
        refundAmountAppointment({ amount: amountToRefund }, props.appointement?.appointmentNumber)
            .then((result: IRefundAmountApiResponse | any) => {
                setRefundStatus(result.appointmentBilling.refundStatus);
                showToastAfterRefund(result.appointmentBilling.refundStatus, result.comment);
                if (result.appointmentBilling.refundStatus.includes(RefundStatus.Failed)) {
                    if (refundField.current) {
                        refundField.current.value = ''
                    }
                }
            })
    }

    const showToastAfterRefund = (refundStatus: RefundStatus, comment: string) => {
        switch (refundStatus) {
            case RefundStatus.Failed:
                toastError(comment);
                break;
            default:
                toastSuccess(comment);
        }
    }

    const saveSettings = (e: any) => {
        e.preventDefault();
        if (!amountIsRefunded() && e.target.refund.value) {
            return toastError("Please refund the amount before submitting");
        }
        if (notesTextAreaWasCleared(e)) {
            setNotes(e.target.notes.value);
            return toastError("Please save the notes before submitting");
        }
        if (!notesAreSaved()) {
            return toastError("Please save the notes before submitting");
        }
        let objectToBeSent: IAppointementBillingUpdate | undefined = {}
        if ((initialValuePaidByInsurance === undefined && e.target.paidinsurance.checked === false)
            || (initialValuePaidByInsurance === true && e.target.paidinsurance.checked === true)
            || (initialValuePaidByInsurance === false && e.target.paidinsurance.checked === false)
        ) {

        } else {
            objectToBeSent.paidByInsurance = e.target.paidinsurance.checked
        }
        if (parseInt(e.target.amountpaidinsurance.value)
            && parseInt(e.target.amountpaidinsurance.value) !== initialValuePaidByInsuranceNumeric
        ) {
            objectToBeSent.amountPaidByInsurance = parseFloat(e.target.amountpaidinsurance.value)
        }
        objectToBeSent.submitted = true;
        updateAppointmentBilling(objectToBeSent);
        props.toggleModal();
    }

    const amountIsRefunded = () => {
        return refundStatus && refundStatus.includes(RefundStatus.Succeeded);
    }

    const notesTextAreaWasCleared = (e: any) => {
        return notes && notes !== e.target.notes.value;
    }

    const notesAreSaved = () => {
        return !((notes !== initialValueNotes) && (savedNotes === undefined || savedNotes !== notes));
    }

    const updateAppointmentBilling = (billing: IAppointementBillingUpdate) => {
        appointmentBillingUpdate(billing, props.appointement?.appointmentNumber)
            .then(({ submitted, refundStatus }: IAppointementBilling | any) => {
                if (submitted && refundStatus) {
                    props.updateBillingStatus()
                }
                props.toggleModal()
            })
            .catch((err) => {
                console.log(err)
                toastError("Something Went Wrong")
            })
    }

    const handleSaveNotesClicked = () => {
        appointmentBillingUpdate({ notes }, props.appointement?.appointmentNumber)
            .then((res: IAppointementBilling | any) => {
                setSavedNotes(notes);
                toastSuccess("Notes are saved successfully!")
            })
            .catch((err) => {
                console.log(err)
                toastError("Saving notes failed!")
            })
    }

    const renderRefundContent = () => {
        if (!refundStatus || refundStatus.includes(RefundStatus.Failed)) {
            return (
                <Row form>
                    <Col md="6" lg="6">
                        <FormGroup className={"form-label-group"}>
                            <Input
                                type="number"
                                id="refund"
                                name="refund"
                                min="0"
                                value={amountToRefund}
                                innerRef={refundField}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value)
                                    const allowed = props.initialFees ? props.initialFees.appointmentPaymentAmount + props.initialFees.appointmentTransactionFee : 79;
                                    if (value > allowed) {
                                        setAmountToRefund(allowed)
                                    } else {
                                        setAmountToRefund(parseFloat(e.target.value))
                                    }
                                }}
                            ></Input>
                            <Label for="refund">Refund Amount<Asterisk /></Label>
                            <FormFeedback>"Valid first name is required."</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                        <LoadingButton
                            isLoading={isRefundLoading}
                            color="primary"
                            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "5%", color: "white", }}
                            onClick={refundAmountApi}
                        >
                            Refund
                        </LoadingButton>
                    </Col>
                </Row >
            )
        } else {
            return (
                <Row form>
                    <Col md="6" lg="6">
                        <FormGroup className={"form-label-group"}>
                            <div>
                                <strong>{"Refunded Amount " + " : "}</strong>
                                {renderBillingAmountToRefund()}
                            </div>
                        </FormGroup>
                    </Col>
                </Row >
            )
        }

    }
    const renderPaidByInsruanceNumeric = () => {
        return (
            <>
                <Row form>
                    <Col md="6" lg="6">
                        <FormGroup className={"form-label-group"}>
                            <Input
                                type="number"
                                id="amountpaidinsurance"
                                name="amountpaidinsurance"
                                required
                                min="0"
                                value={paidByInsuranceNumeric}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value)
                                    setPaidByInsuranceNumeric(value)
                                }
                                }
                            ></Input>
                            <Label for="amountpaidinsurance">Amount Paid By Insurance</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>{"Total Fees" + " : "}</strong>
                        {renderTotalFees()}
                    </Col>
                </Row>
            </>
        )
    }

    const renderTotalFees = () => {
        if (paidByInsuranceNumeric !== null && paidByInsuranceNumeric !== undefined && !isNaN(paidByInsuranceNumeric) && paidByInsuranceNumeric !== 0) {
            if (billingValues?.refundedAmount || billingValues?.refundedAmount === 0) {
                return (billingValues?.refundedAmount + paidByInsuranceNumeric)
            } else {
                if (amountToRefund) {
                    return (amountToRefund + paidByInsuranceNumeric);
                } else {
                    return paidByInsuranceNumeric;
                }
            }
        } else {
            return (
                <div>No Amount Specified</div>
            )
        }
    }

    const renderBillingAmountToRefund = () => {
        if (
            billingValues?.refundStatus
            && !billingValues?.refundStatus.includes(RefundStatus.Failed)
            && (billingValues?.refundedAmount || billingValues?.refundedAmount === 0)
        ) {
            return (billingValues?.refundedAmount)
        } else {
            return (amountToRefund)
        }
    }

    const renderSaveNotesButton = () => {
        return (
            <Button
                color="link"
                onClick={handleSaveNotesClicked}
                size="sm"
            >
                <i className="far fa-save fa-lg"></i>{" "}
                <span>Save Notes</span>
            </Button>
        )
    }

    const renderInitialFeesAndPayedAmount = () => {
       const basePrice = props.initialFees?.appointmentPaymentAmount ?? 0;
       const transactionAmount =
          props.initialFees?.appointmentTransactionFee ?? 0;
       const initialTotal = basePrice + transactionAmount;

       const payedAmountNum = parseFloat(
          props.appointement?.payment.amount ?? '0'
       );
       const discount = parseFloat(props.appointement?.payment.discount ?? '0');
       const promoCode = props.appointement?.payment.promoCode ?? '';

       let discountString = '';
       let promoCodeString = '';

       if (isNil(discount) || discount === 0) discountString = '0$';
       else if (discount === 1) {
          discountString = 'FULL';
          promoCodeString = ` - ${promoCode}`;
       } else {
          let initialPrice =
             (payedAmountNum - transactionAmount) / (1 - discount);

          discountString = `${initialPrice * discount}$`;
          promoCodeString = ` - ${promoCode}`;
       }

       return (
          <div style={{ width: 'fit-content' }}>
             <div style={{ textWrap:'nowrap' }}>
                <strong>{'Current System Fees : '}</strong>
                {props.initialFees ? `${initialTotal}$` : 'No Amount Specified'}
             </div>
             <div style={{ textWrap:'nowrap' }}>
                <strong>{'Payed Amount : '}</strong>
                {`${payedAmountNum}$`}
             </div>
             <div style={{ textWrap:'nowrap' }}>
                <strong>{'Discount : '}</strong>
                {discountString + promoCodeString}
             </div>
          </div>
       );
    };

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} >
                <Form
                    id="manage_form"
                    onSubmit={(e) => { saveSettings(e) }}
                >
                    <ModalHeader toggle={props.toggleModal}>Manage Insurance Billing</ModalHeader>
                    <ModalBody >
                        <Row form>
                            <Col md="6" lg="6">
                                <FormGroup className={"form-label-group"}>
                                    {renderInitialFeesAndPayedAmount()}
                                </FormGroup>
                            </Col>
                        </Row >
                        {renderRefundContent()}
                        <Row form>
                            <Col md="6" lg="6">
                                <FormGroup className={"form-label-group"}>
                                    <div className="custom-checkbox custom-control">
                                        <Input
                                            id="paidinsurance"
                                            name="paidinsurance"
                                            type="checkbox"
                                            checked={paidByInsurance}
                                            // disabled={!enabled}
                                            disableUnderline={true}
                                            onChange={(e) => {
                                                setPaidByInsurance(!paidByInsurance)
                                                console.log(e.target.checked)
                                            }}
                                        // className="custom-control-input"
                                        />
                                        <Label>Paid By Insurance</Label>
                                        <br />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row >
                        {renderPaidByInsruanceNumeric()}
                        <Row form>
                            <Col md="6" lg="6">
                                <FormGroup className={"form-label-group"}>
                                </FormGroup>
                            </Col>
                        </Row >
                        <Row form>
                            <Col md="12" lg="12" sm="12">
                                <FormGroup className={"form-label-group"}>
                                    <TextField
                                        name="notes"
                                        maxLength={300}
                                        labelText="Notes"
                                        type="textarea"
                                        defaultValue={billingValues?.notes}
                                        textChanged={(text) => { setNotes(text) }}
                                    />
                                </FormGroup>
                                {renderSaveNotesButton()}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Submit</Button>{' '}
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

