import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { Routes } from "../../config/Routes";
import { TextField } from "../../components/commons/TextField";
import { IPhysicianRegistrationForm, usePhysicianRegistrationAPI, getUSStatesLookup, canRegister } from "../../core/service/services";
import { Button, Form, CardBody, CardTitle, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { toastError } from "../../App";
import { titleOptions, IUSState } from "../settings/PhysicianSettings"
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Asterisk from "../../components/commons/Asterisk";
import moment from "moment";
import { LoadingButton } from "../../components/commons/LoadingButton";
import { PrimaryRegistartionForm } from "./PrimaryRegistrationForm";
export const validateEmail = (email?: string| null) => {
  if (email == null || email.length  < 1){
    return false;
  }
  let re = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
  return re.test(email);
}

export const validateStr = (value?: string | null) => {
  if (!value || value == null || value.length  < 1){
    return false;
  }
  var re = new RegExp("^[a-zA-Z '-\s]+$") ;
  return re.test(value);
}
export interface IProps extends RouteComponentProps {
  email?: string;
}

interface IPhysicianPrimaryInfo {
  email: string;
  password : string;
  passwordConfirmation: string;
  firstname: string;
  lastname: string;
  middlename?: string;
}


export const PhysicianRegistration: React.FC<IProps> = (props: IProps) => {
  const [page, setPage] = React.useState<1 | 2>(1);
  const [primaryInfo, setPrimaryInfo] = React.useState<IPhysicianPrimaryInfo>()
  const [dob, setDob] =  React.useState<Date | string>();
  const [states, setStates] = React.useState<IUSState[]>([]);

  const signUpService = usePhysicianRegistrationAPI();
  let email = (props.location?.state as any)?.email;
  const controls = {
    firstName: React.createRef<TextField>(),
    middleName: React.createRef<TextField>(),
    lastName: React.createRef<TextField>(),
    email: React.createRef<TextField>(),
    password: React.createRef<TextField>(),
    passwordConfirmation: React.createRef<TextField>(),
    title: React.createRef<HTMLInputElement>(),
    officePhone: React.createRef<TextField>(),
    mobilePhone: React.createRef<TextField>(),
    primaryFax: React.createRef<TextField>(),
    addressLine1: React.createRef<TextField>(),
    addressLine2: React.createRef<TextField>(),
    city: React.createRef<TextField>(),
    stateId: React.createRef<HTMLInputElement>(),
    zipCode: React.createRef<TextField>(),
    dea: React.createRef<TextField>(),
    specialty: React.createRef<TextField>(),
    npi: React.createRef<TextField>()
  };

  React.useEffect(() => {
     if (signUpService.service.status === 'loaded') {
        navigate(Routes.successfulSignup);
     } else if (signUpService.service.status === 'validationError') {
        signUpService.service.errors.forEach((error) =>
           toastError(error.message)
        );
     } else if (signUpService.service.status === 'error') {
        toastError('Invalid Data');
     }
  });

  React.useEffect(() => {
    getUSStatesLookup()
      .then((res) => {
        setStates(res);
      })
      .catch((error) => console.error(error));
  }, [])


  const submitPrimaryInfo = async (e: React.FormEvent) => {
    // cleanup errors 
    e.preventDefault()
    // controls.firstName.current?.setErrors([]);
    // controls.middleName.current?.setErrors([]);
    // controls.lastName.current?.setErrors([]);
    // controls.email.current?.setErrors([]);
    // controls.password.current?.setErrors([]);
    // controls.passwordConfirmation.current?.setErrors([]);

    let errors: string[] = []
    let pass1 = controls.password.current?.getValue();
    let pass2 = controls.passwordConfirmation.current?.getValue();
   
    //TODO: enhance error messages to use mpre than one error per field
    if (!validateStr(controls.firstName.current?.getValue())) {
      let message = "A valid first name is expected"
      errors.push(message);
      controls.firstName.current?.setErrors([message])
    }
    if (!validateStr(controls.lastName.current?.getValue())) {
      let message = "A valid last name is expected"
      errors.push(message);
      controls.lastName.current?.setErrors([message])
    }

    if (!validateEmail(controls.email.current?.getValue())) {
      let message = "A valid email is expected"
      errors.push(message);
      controls.email.current?.setErrors([message])
    }

    if ((pass1?.length || 0) < 8) {
      let message = "Password must be more than 8 letters of length"
      errors.push(message);
      controls.password.current?.setErrors([message])
    }
    if (pass2 !== pass1){
      let message = "Passwords must match"
      errors.push(message);
      controls.passwordConfirmation.current?.setErrors([message])
    }

    if (errors.length > 0) {
      return;
    }

    async function callRegistrationAllowed () {
      const res = await canRegister(controls.email.current?.getValue() || "");
      if (!res.canRegister) {
        const message = "Please use a different email address"
        toastError(message);
        controls.email.current?.setErrors([message])
        return
      }
      setPrimaryInfo({
        email: controls.email.current?.getValue() || "",
        firstname: controls.firstName.current?.getValue() || "",
        middlename: controls.middleName.current?.getValue() || "",
        lastname: controls.lastName.current?.getValue()|| "",
        password: controls.password.current?.getValue() || "",
        passwordConfirmation: controls.passwordConfirmation.current?.getValue() || "",
      })
      setPage(2)
    }
    callRegistrationAllowed()
  };

  const prevPage = () => {
    setPage(1)
  }

  const submitRegistrationForm = (e: React.FormEvent) => {
    e.preventDefault()
    let errors: string[] = []
    if (!dob) {
      let message = "A valid date of birth is expected"
      toastError(message)
      errors.push(message);
    }
    if (!validateStr(controls.primaryFax.current?.getValue())) {
      let message = "A valid primary fax is expected"
      errors.push(message);
      controls.primaryFax.current?.setErrors([message])
    }
    if (!validateStr(controls.addressLine1.current?.getValue())) {
      let message = "A valid address is expected"
      errors.push(message);
      controls.addressLine1.current?.setErrors([message])
    }
    if (!validateStr(controls.city.current?.getValue())) {
      let message = "A valid city is expected"
      errors.push(message);
      controls.city.current?.setErrors([message])
    }
    if (!validateStr(controls.stateId.current?.value)) {
      let message = "A valid state is expected"
      toastError(message)
      errors.push(message);
    }
    if (!validateStr(controls.zipCode.current?.getValue())) {
      let message = "A valid Zip Code is expected"
      errors.push(message);
      controls.zipCode.current?.setErrors([message])
    }
    if (!validateStr(controls.npi.current?.getValue())) {
      let message = "A valid NPI is expected"
      errors.push(message);
      controls.npi.current?.setErrors([message])
    }
    if (!validateStr(controls.dea.current?.getValue())) {
      let message = "A valid DEA is expected"
      errors.push(message);
      controls.dea.current?.setErrors([message])
    }
    if (!validateStr(controls.specialty.current?.getValue())) {
      let message = "A valid specialty is expected"
      errors.push(message);
      controls.specialty.current?.setErrors([message])
    }
    if (!primaryInfo || errors.length > 0) {
      return;
    }
    let phRegistrationForm: IPhysicianRegistrationForm = {
      email: primaryInfo.email,
      password: primaryInfo.password,
      passwordConfirmation: primaryInfo.passwordConfirmation,
      contactInfo: {
        title: controls.title.current?.value,
        firstname: primaryInfo.firstname,
        lastname: primaryInfo.lastname,
        middlename: primaryInfo.middlename,
        officePhone: controls.officePhone.current?.getValue(),
        mobilePhone: controls.mobilePhone.current?.getValue() || "",
        dob: moment(dob).format("YYYY-MM-DD"),
        primaryFax: controls.primaryFax.current?.getValue() || "",
        addressLine1: controls.addressLine1.current?.getValue() || "",
        addressLine2: controls.addressLine2.current?.getValue(),
        city: controls.city.current?.getValue() || "",
        stateId : parseInt(controls.stateId.current?.value || "0"),
        zipCode: controls.zipCode.current?.getValue() || "",
      },
      physicianInfo: {
        dea: controls.dea.current?.getValue() || "",
        specialty: controls.specialty.current?.getValue() || "",
        npi: controls.npi.current?.getValue() || "",
      }
    }
    
    signUpService.callService(phRegistrationForm).then((resData) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  const renderSecondaryForm = () => (
    <fieldset disabled={signUpService.service.status === "loading"}>
    <Form name="regForm2" onSubmit={(e) => submitRegistrationForm(e)} className={page === 2? "" : "d-none"}>
      <Row>
        <Col md="6">
          <FormGroup>
            <Input type="select" name="title" innerRef={controls.title}>
              <option value="">Select Title</option>
              {titleOptions.map(option => (
                <option value={option}>{option}</option>
              ))}
            </Input>
          </FormGroup>
          <div className="form-label-group ">
            <DatePicker
              selected={typeof dob === "string"? moment(dob, "YYYY-MM-DD").toDate() : dob}
              onChange={(date) => {
                if (date) setDob(date);
              }}
              showYearDropdown={true}
              scrollableYearDropdown={true}
              yearDropdownItemNumber={90}
              dateFormat="MM-dd-yyyy"
              maxDate={new Date()}
              name="dob"
              required
            />
            <Label>Date of birth <Asterisk/></Label>
          </div>
          <TextField type="text" name="npi" required labelText="NPI" ref={controls.npi}/>
          <TextField type="text" name="dea" required labelText="DEA" ref={controls.dea}/>
          <TextField type="text" name="specialty" required labelText="Specialty" ref={controls.specialty}/>
          <TextField type="phone" name="officePhone" required labelText="Office Phone" ref={controls.officePhone}/>
          <TextField type="phone" name="mobilePhone" required labelText="Mobile Phone" ref={controls.mobilePhone}/>
        </Col>
        <Col md="6">
          <TextField type="phone" name="primaryFax" required labelText="Primary fax" ref={controls.primaryFax}/>
          <TextField type="text" name="addressLine1" required labelText="Address Line 1" ref={controls.addressLine1}/>
          <TextField type="text" name="addressLine2" labelText="Address Line 2" ref={controls.addressLine2}/>
          <TextField type="text" name="city" required labelText="City" ref={controls.city}/>
          <FormGroup>
            <Label>State:</Label>
            <Input type="select" className="form-control form-control-sm" innerRef={controls.stateId}>
              <option value="">Select your state</option>
              {states.map((state) => (
                <option value={state.id}>{state.name}</option>
              ))}
            </Input>
          </FormGroup>
          <TextField type="text" name="zipCode" required labelText="Zip Code" ref={controls.zipCode}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <LoadingButton isLoading={signUpService.service.status === "loading"} type="submit" size="lg" color="primary" 
            className="text-uppercase mt-4 w-50 m-auto" block={true}>Register</LoadingButton>
        </Col>
        <Col md="12">
          <Button color="link" onClick={prevPage}>{"<< Back"}</Button>  
        </Col>
      </Row>
      
    </Form>
    </fieldset>
  )
  
  return (
    <>
      <SmallCardFrame bodyClassName="tc-bg" navItem={null}>
        {page === 1 && 
        <div className="card-img-left physician d-none d-md-flex">
        </div>
        }
        <CardBody>
          <CardTitle className="text-center">Physician Registration</CardTitle>
          <PrimaryRegistartionForm 
            email={email} 
            submitPrimaryInfo={submitPrimaryInfo} 
            controls={controls}
            page={page}
            routesTo={Routes.physicianSignin}
            userType="Physician"
            />
          {renderSecondaryForm()}
        </CardBody>
      </SmallCardFrame>
    </>
  );
};
