import { useState } from "react";
import { Service, ServiceResponseError, ServiceResponseValidationError } from "../types/Service";

export const useGenericServiceCall = <OutPut, Input = unknown, ErrorType = unknown>(
  url: string,
  method: "POST" | "GET",
  tokenProvider?: () => string | null,
  tokenUpdater?: (token: string) => {},
  allowedHttpCustomErrorCodes?: number[] | null
) => {
  const [service, setService] = useState<Service<OutPut, ErrorType>>({
    status: "init",
  });

  const callService = (data: Input) => {
    setService({ status: "loading" });

    const headers = new Headers();
    const token = tokenProvider && tokenProvider();
    headers.append("Content-Type", "application/json; charset=utf-8");
    if (token) {
      headers.append("authorization", "Bearer " + token);
    }
    // debugger;
    return new Promise<void>((resolve, reject) => {
      const body = data && JSON.stringify(data);
      fetch(url, {
        method: method,
        body: body,
        headers,
      })
        .then(async (response) => {
          const responseBody = await response.json();

          const newToken = response.headers.get('patientAccessToken');
          if (newToken && tokenUpdater) tokenUpdater(newToken);

          if ((allowedHttpCustomErrorCodes || []).includes(response.status)) {
            setService({
              status: 'custormError',
              errorResponse: responseBody,
            });

            reject(responseBody);
            return;
          }

          if (response.status === 422) {
            const errorResponse =
              responseBody as ServiceResponseError<ServiceResponseValidationError>;

            setService({
              status: 'validationError',
              message: errorResponse.message,
              errors: errorResponse.errors,
            });
            reject(errorResponse);
          } else if (response.status >= 400) {
            const errorResponse = responseBody as ServiceResponseError;

            const error = new Error(errorResponse.message);
            setService({ status: 'error', error });
            reject(error);
          } else {
            setService({ status: 'loaded', payload: responseBody });
            resolve();
          }
        })
        .catch((error) => {
          if (error) {
            setService({ status: "error", error });
            reject(error);
          }
        });
    });
  };

  return {
    service,
    callService,
  };
};

// export useGenericServiceCall;
